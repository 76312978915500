import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CertificatePage, NotFound } from 'pages';
import { usePageTracking } from 'modules/utils/tracking';

const PageRoutes = () => {
  usePageTracking();

  return (
    <Routes>
      <Route path="/:id" element={<CertificatePage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export const Router = () => (
  <BrowserRouter>
    <PageRoutes />
  </BrowserRouter>
);
