const hmColors = {
  hmBrandPurple: '#794c91',
  hmBrandPurple50: 'rgba(121, 76, 145, 0.5)',
  hmBrandYellow: '#fdc010',
  hmBlack: '#3e3e3e',
  hmGrey1: '#e8e8e8',
  hmGrey2: '#c3c3c3',
  hmGrey3: '#898989',
  hmRed: '#e53d3d',
  hmGreen: '#60cb92',
  hmBlue: '#068deb',
  hmPink: '#dd6d8f',
  hmPurple2: '#cac1d3',
};

const badgeColors = {
  badgeBackground: '#ECE9F2',
  badgeText: '#C6C2D3',
};

export const colors = {
  ...hmColors,
  ...badgeColors,
  white: '#fff',
  whiteTransparent: 'rgba(255, 255, 255, 0.3)',
  heather: '#a59ab0',
  paleLavender: '#fbf8ff',
  paleGrey: '#fcfbfd',
  paleRed: '#db5847',
};
