import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import favicon from 'assets/images/favicon.ico';

export const Head = ({ title, metadata }) => (
  <Helmet title={title}>
    <link rel="shortcut icon" type="image/ico" href={favicon} />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Roboto:wght@400;500;900&display=swap"
      rel="stylesheet"
    />
    <meta property="og:title" content={metadata?.title} />
    <meta property="og:description" content={metadata?.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
  </Helmet>
);

Head.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
};

Head.defaultProps = {
  title: null,
  metadata: {
    title: '100mentors',
    description: null,
    image: null,
  },
};
