import styled from 'styled-components';

import { colors } from 'styles/theme';

import { ReactComponent as CertificateStamp } from 'assets/images/certificate-stamp.svg';
import { Utils } from './certificate.Certificate.Styled';

export default {
  Flag: styled.div`
    background-color: ${colors.badgeBackground};
    text-align: center;
    height: 200px;
    width: 150px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
    padding: 30px;
    margin-left: auto;
    margin-top: calc(-1 * var(--certificate-padding, 0));

    margin-bottom: unset;

    @media only screen and (min-width: 800px) {
      order: 2;
      width: 250px;
      height: 330px;
      margin-bottom: -50%;
    }
  `,
  Title: styled(Utils.Bold)`
    color: ${colors.badgeText};
    text-transform: uppercase;
    font-size: 24px;
    display: inline-block;
    margin-top: 10px;
    padding: 0;

    @media only screen and (max-width: 800px) {
      font-size: 14px;
      margin: 15px;
    }
  `,
  CertificateStamp: styled(CertificateStamp)`
    width: 80%;
    height: auto;
  `,
};
