import { useEffect } from 'react';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import notAvailableIcon from 'assets/images/not-available.svg';
import notFoundIcon from 'assets/images/404.svg';

import { Head } from 'modules/html';
import { ContentNotAvailable } from 'modules/ui';
import { Certificate } from 'modules/certificate';
import S from './certificate.Certificate.Styled';

const metadataList = [
  {
    title: 'Join role models like you on 100mentors',
    description: 'Answer questions like these in 100-second videos to start making your impact today.',
  },
];

const query = gql`
  query PublicCertificate($id: ID!) {
    certificatePublic(id: $id) {
      id
      userDisplayName
      data {
        creativity
        problemSolving
        criticalThinking
      }
      createdAt
    }
  }
`;

export const CertificatePage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(query, { variables: { id } });
  const randomMetadata = _.sample(metadataList);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (!loading && data && window) {
      window.prerenderReady = true;
    }
  }, [data, loading]);

  // Loading
  if (loading && !data) {
    return null;
  }

  // Not found
  if (!data?.certificatePublic) {
    return (
      <>
        <Head
          title="Certificate not found | 100mentors"
          metadata={{
            title: randomMetadata.title,
            description: randomMetadata.description,
          }}
        />
        <ContentNotAvailable
          icon={!data?.certificatePublic ? notFoundIcon : notAvailableIcon}
          text={!data?.certificatePublic ? "We couldn't find this Certificate" : undefined}
        />
      </>
    );
  }

  const { certificatePublic } = data ?? {};
  const { data: statsData, createdAt } = certificatePublic ?? {};
  const stats = {
    creativity: statsData.creativity * 100,
    problemSolving: statsData.problemSolving * 100,
    criticalThinking: statsData.criticalThinking * 100,
  };

  const formatedDate = formatDate(createdAt);
  const pageMetaData = {
    title: `${certificatePublic?.userDisplayName} • 100mentors Learner Certificate • Soft Skills Development`,
    description:
      'One more learner developed, measured and certified their soft skills, one question at the time, on 100mentors',
  };

  return (
    <S.CertificatePage>
      <Head title={`${certificatePublic?.userDisplayName}'s certificate | 100mentors `} metadata={pageMetaData} />
      <S.Body>
        <S.Content>
          <Certificate name={data.certificatePublic.userDisplayName} skills={stats} date={formatedDate} />
        </S.Content>
      </S.Body>
    </S.CertificatePage>
  );
};

const formatDate = (date) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
