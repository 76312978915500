import styled from 'styled-components';

import { colors } from 'styles/theme';

export const Utils = {
  Bold: styled.p`
    font-weight: 500;
    font-size: 18px;
  `,
};

export default {
  Container: styled.main`
    --certificate-max-width: 1000px;
    color: ${colors.hmGrey3};
    font-family: Roboto, sans-serif;
    font-weight: 400;

    background-color: ${colors.white};
    box-sizing: content-box;
    display: flow-root;
    margin: auto;
    width: 100vw;
    max-width: var(--certificate-max-width);
    padding: clamp(0px, calc((100vw - var(--certificate-max-width)) / 2), 35px);
  `,
  Wrapper: styled.div`
    --certificate-padding: 50px;
    background-color: ${colors.paleLavender};
    border: 10px solid ${colors.hmBrandYellow};
    padding: var(--certificate-padding);
    box-sizing: border-box;

    @media only screen and (max-width: 690px) {
      --certificate-padding: 25px;
    }
  `,
  MainInfoContainer: styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
  `,
  Logo: styled.img`
    width: 140px;

    &:hover {
      transform: translateY(-3px);
    }

    transition: transform 0.25s ease;
  `,
  Title: styled.h2`
    text-transform: capitalize;
    margin-top: 2em;
    margin-bottom: 15px;
    color: ${colors.hmBrandPurple};
    font-weight: 900;
    font-size: 37px;

    @media only screen and (max-width: 800px) {
      margin-top: 20px;
    }
  `,
  Date: styled.p`
    margin: 0;
  `,
  Name: styled.h1`
    border-bottom: 1px solid ${colors.hmGrey3};
    color: ${colors.hmBlack};
    font-family: 'Gloria Hallelujah', Roboto;
    margin-bottom: 14px;
    line-height: 1.5em;
  `,
  Description: styled.p`
    margin: 0;
  `,
  SkillsContainer: styled.section`
    margin-top: 23px;
    margin-bottom: 32px;
    display: flex;
    gap: 23px;

    @media only screen and (max-width: 640px) {
      flex-direction: column;
    }
  `,
  Footer: styled.footer`
    display: flex;
    text-align: right;
    gap: 5%;
    justify-content: space-between;

    @media only screen and (max-width: 660px) {
      flex-direction: column;
      gap: 35px;
      text-align: left;
    }
  `,
  SignaturesContainer: styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
    gap: 60px;

    @media only screen and (max-width: 800px) {
      ${Utils.Bold}, * {
        font-size: 14px;
        margin-right: unset;
      }
    }

    @media only screen and (max-width: 660px) {
      gap: 30px;
    }

    @media only screen and (max-width: 380px) {
      flex-direction: column;
      align-items: center;
    }
  `,
  FooterInfo: styled.div`
    margin-top: auto;
    max-width: 100%;
    width: 400px;

    p {
      margin: 0;
    }

    > :last-child {
      margin-top: 16px;
      margin-bottom: 0;
    }
  `,
};
