import styled from 'styled-components';

export default {
  FontAwesomeImage: styled.img`
    height: ${({ height }) => `${height}px`};
    // Font awesome svg inline style
    display: inline-block;
    font-size: inherit;
    width: 1em;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  `,
};
