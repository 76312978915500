import PropTypes from 'prop-types';

import { Utils } from './certificate.Certificate.Styled';
import S from './certificate.Signature.Styled';

export const Signature = ({ sign, name, title, small }) => (
  <S.Container>
    <S.Signature src={sign} alt={`${name}'s signature`} small={small} />
    <p>{name}</p>
    <Utils.Bold>{title}</Utils.Bold>
  </S.Container>
);

Signature.propTypes = {
  sign: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

Signature.defaultProps = {
  small: false,
};
