import PropTypes from 'prop-types';

import S from './certificate.Skill.Styled';

export const Skill = ({ title, value }) => (
  <S.Container>
    <S.Text>{title}</S.Text>
    <S.Percentage>{Math.floor(value)}</S.Percentage>
    <S.Text>%</S.Text>
  </S.Container>
);

Skill.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
