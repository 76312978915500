import { createGlobalStyle } from 'styled-components';
import { colors, font } from './styles/theme';

export default createGlobalStyle`
  html, body {
    margin: 0;
    font-family: ${font.fonts.roboto};
    color: ${colors.hmBlack};
  }
`;
