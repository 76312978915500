import { ApolloProvider } from '@apollo/client';
import GlobalCSS from 'GlobalCSS';
import { HelmetProvider } from 'react-helmet-async';

import { Router, apolloClient } from 'modules/core';

if (window) {
  window.prerenderReady = false;
}

const App = () => (
  <HelmetProvider>
    <ApolloProvider client={apolloClient}>
      <GlobalCSS />
      <Router />
    </ApolloProvider>
  </HelmetProvider>
);

export default App;
