import PropTypes from 'prop-types';

import logo from 'assets/images/logo.svg';
import meliSign from 'assets/images/meli-signature.png';
import goulasSign from 'assets/images/goulas-signature.png';

import { Skill } from './certificate.Skill';
import { Signature } from './certificate.Signature';
import { CertificateBadge } from './certificate.CertificateBadge';
import S, { Utils } from './certificate.Certificate.Styled';

export const Certificate = ({ name, date, skills }) => (
  <S.Container>
    <S.Wrapper>
      <S.MainInfoContainer>
        <CertificateBadge />

        <div>
          <a href="https://www.100mentors.com">
            <S.Logo src={logo} />
          </a>
          <S.Title>soft skills development</S.Title>
          <S.Date>Date: {date}</S.Date>
          <S.Name>{name}</S.Name>
          <S.Description>
            you are now a Certified 100mentors Learner.
            <br />
            Congratulations on developing your inquiry-based skills!
          </S.Description>
        </div>
      </S.MainInfoContainer>

      <p>Your scores are:</p>
      <S.SkillsContainer>
        <Skill title="Critical Thinking" value={skills.criticalThinking} />
        <Skill title="Problem Solving" value={skills.problemSolving} />
        <Skill title="Creativity" value={skills.creativity} />
      </S.SkillsContainer>

      <S.Footer>
        <S.SignaturesContainer>
          <Signature sign={meliSign} name="Dr. Kalliopi Meli" title="Head of Research" small />
          <Signature sign={goulasSign} name="Dr. Sofoklis Goulas" title="Principal Researcher" small />
        </S.SignaturesContainer>
        <S.FooterInfo>
          <Utils.Bold>Verified by 100mentors</Utils.Bold>
          <p>
            100mentors has confirmed the identity of this individual and their participation in this mentorship program
          </p>
          <p>Duration of credential: 6 months</p>
        </S.FooterInfo>
      </S.Footer>
    </S.Wrapper>
  </S.Container>
);

Certificate.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  skills: PropTypes.shape({
    criticalThinking: PropTypes.number.isRequired,
    problemSolving: PropTypes.number.isRequired,
    creativity: PropTypes.number.isRequired,
  }).isRequired,
};
