import styled from 'styled-components';

import tiles from 'assets/images/tiles.svg';
import { colors } from 'styles/theme';

export default {
  CertificatePage: styled.div`
    background-image: url('${tiles}');
    background-color: ${colors.paleGrey};
    height: 100vh;
    width: 100vw;
  `,
  Body: styled.div``,
  Content: styled.div`
    height: 100vh;
    display: flex;

    @media only screen and (min-width: 1024px) {
      margin: 0;
    }
  `,
};
