import styled from 'styled-components';
import { colors, font } from 'styles/theme';
import { darken } from 'polished';

export default {
  Button: styled.button`
    padding: 15px 20px;
    border: none;
    border-radius: 2px;
    box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
    transition: box-shadow 250ms ease;
    background-color: ${colors.hmBrandPurple};
    transition: background-color 250ms ease;
    color: ${colors.white};
    font-family: ${font.fonts.roboto};
    font-weight: ${font.weights.bold};
    font-size: ${font.sizes.s};
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: ${darken(0.05, colors.hmBrandPurple)};
    }
    &:focus {
      outline: none;
    }
    &:active {
      box-shadow: none;
    }
  `,
};
