export const font = {
  fonts: {
    roboto: `'Roboto', sans-serif`,
    lato: `'Lato', sans-serif`,
  },
  sizes: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '22px',
    xxl: '26px',
    xxxl: '32px',
  },
  weights: {
    light: '300',
    normal: '400',
    bold: '600',
  },
};
