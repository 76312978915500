import PropTypes from 'prop-types';

import logo from 'assets/images/logo.svg';

import S from './ui.ContentNotAvailable.Styled';

export const ContentNotAvailable = ({ text, icon }) => (
  <S.ContentNotAvailable>
    <a href="https://www.100mentors.com">
      <S.Logo src={logo} />
    </a>
    <S.Icon src={icon} />
    <S.Text>{text}</S.Text>
  </S.ContentNotAvailable>
);

ContentNotAvailable.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
};

ContentNotAvailable.defaultProps = {
  text: 'This Q&A is no longer available.',
  icon: '',
};
