import styled from 'styled-components';

export default {
  Container: styled.div`
    text-align: center;
    min-width: max-content;

    * {
      line-height: 1.4em;
      margin: 0;
    }
  `,
  Signature: styled.img`
    max-height: ${({ small }) => (small ? '50px' : '100px')};
    margin: 0;
  `,
};
