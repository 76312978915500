import styled from 'styled-components';
import { colors } from 'styles/theme';
import { darken } from 'polished';

export default {
  Link: styled.a`
    text-decoration: none;
    color: ${colors.hmBlue};

    &:hover {
      color: ${darken(0.05, colors.hmBlue)};
    }
  `,
};
