import styled from 'styled-components';

import { colors } from 'styles/theme';

import { Utils } from './certificate.Certificate.Styled';

export default {
  Container: styled.div`
    display: inline-block;
  `,
  Text: styled(Utils.Bold)`
    display: inline;
  `,
  Percentage: styled.p`
    font-family: 'Gloria Hallelujah', Roboto;
    line-height: 1em;
    position: relative;
    bottom: 3px;
    width: 3em;
    margin-block: 0;
    display: inline-block;
    color: ${colors.hmBlack};
    border-bottom: 1px solid ${colors.hmGrey3};
    text-align: center;
  `,
};
